import React from 'react';
import { BlueVectorImg } from '../Home/HeroSection';
import { INLINES, BLOCKS, MARKS, PARAGRAPH } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const sharedClasses = 'text-[#07509D]';
const bodyClasses = 'text-lg text-back';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data;
      return (
        <a href={uri} className="underline">
          {children}
        </a>
      );
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <h1
          className={`mb-4 text-4xl text-black md:text-5xl lg:text-6xl font-bold ${sharedClasses}`}
        >
          <br />
          {children}
        </h1>
      );
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <h1
          className={`mb-4 text-3xl text-black md:text-5xl lg:text-6xl font-bold ${sharedClasses}`}
        >
          <br />
          {children}
        </h1>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <h1 className={`text-3xl font-bold  ${sharedClasses}`}>
          <br />
          {children}
        </h1>
      );
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return (
        <h1 className={`text-2xl font-bold  ${sharedClasses}`}>
          <br />
          {children}
        </h1>
      );
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return (
        <h1 className={`text-[22px] font-bold  ${sharedClasses}`}>
          <br />
          {children}
        </h1>
      );
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return (
        <h1 className={`md:text-md lg:text-lg font-bold  ${sharedClasses}`}>
          <br /> {children}
        </h1>
      );
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return (
        <li
          className={`my-2 text-lg  gap-1 list-item list-disc before:hidden    ${bodyClasses} `}
        >
          {children}
        </li>
      );
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return (
        <ol className={`list-disc my-2 text-lg ${bodyClasses}`}>{children}</ol>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <ul className={`my-2 text-lg list-item  pl-5 ${bodyClasses} `}>
          {children}
        </ul>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="md:text-md lg:text-lg w-10/12"> {children}</p>
    ),
  },
};

const NewsTemplate = ({ data }) => {
  return (
    <main className="container py-7 ">
      <section className="grid">
        <div>
          <h3 className="text-secondary font-medium	">{data?.createdDate}</h3>
          <h1 className="text-primary lg:text-5xl text-2xl md:text-4xl font-bold lg:w-7/12">
            {data?.title}
          </h1>
          <p className="md:text-md lg:text-lg w-10/12 mt-5">
            {data?.description?.description}
          </p>
          <div className="w-11/12 flex flex-col mx-auto my-14 relative">
            <img
              src={BlueVectorImg}
              className={
                'absolute sm:w-60 w-32 sm:-top-24 -top-16 sm:-right-24 -right-16 z-0'
              }
              alt=""
            />

            <div className="z-10 bg-white">
              <img
                src={data?.image?.file.url}
                alt=""
                className="lg:h-96 md:h-72 w-full  object-cover lg:p-4 p-2 md:rounded-3xl	rounded-xl"
                style={{
                  boxShadow: ' 0px 4.87093px 60.8866px rgba(10, 80, 159, 0.25)',
                }}
              />
            </div>
          </div>
            {data?.richTextDescription &&
              renderRichText(data?.richTextDescription, options)}
        </div>
      </section>
    </main>
  );
};

export default NewsTemplate;
